import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5em;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const Ecovo = () => {
    return ( 
        <div>
            <main className="contenedor2">
                <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <Link to={"../pd"}><h3 className='scrumb'>Projects</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <h3 className='scrumb'>Ecovo</h3>
                </div>    
                <h3 className="copete">Chicken coop, flatpack style</h3>
                <Ul>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen ecovo' src="/img/pd/ecovo/01.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen ecovo' src="/img/pd/ecovo/02.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen ecovo' src="/img/pd/ecovo/03.jpg" />
                    </Divgroup>

                </Ul>
            </main>
            
        </div>
     );
}
 
export default Ecovo;