import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const Corporeal = () => {

    return ( 
        <div>
            <main className="contenedor2">
                <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <Link to={"../pd"}><h3 className='scrumb'>Projects</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <h3 className='scrumb'>Corporeal</h3>
                </div>
                <Ul>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/18.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/17.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/16.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/15.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/19.jpg" />
                    </Divgroup>
                    {/* <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/13.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/14.jpg" />
                    </Divgroup> */}
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/06.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/07.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/08.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/09.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/11.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen corporeo' src="/img/pd/corporeos/12.jpg" />
                    </Divgroup>
                </Ul>
                
            </main>
        </div>
     );
}
 
export default Corporeal;