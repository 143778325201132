import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        padding: 1rem;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const Pd = () => {
    return ( 
        <div>
            <main className="contenedor2">
            <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <h3 className='scrumb'>Projects</h3>
                </div>
            <Ul>
                <Divgroup>
                    <Link className="boton" to={"pd/totem"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/totem/tot1.jpg" />
                        <p className='legenda'>Totem (2023)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/solarium"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/solarium/featured.jpg" />
                        <p className='legenda'>Solarium (2023)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/cardales"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/cardales/featured.jpg" />
                        <p className='legenda'>"Los Cardales" Crest (2022)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/ballclock"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/ballclock/featured.jpg" />
                        <p className='legenda'>Ballclock (2021)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/montacargas"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/montacargas/featured.jpg" />
                        <p className='legenda'>Elevator (2021)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/parking"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/park/featured.jpg" />
                        <p className='legenda'>Parking (2020)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/rondo"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/rondo/featured.png" />
                        <p className='legenda'>Rondó (2018)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/chipi"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/chipi/featured.png" />
                        <p className='legenda'>Chipi (2017)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/tirolesa"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/tirolesa/featured.jpg" />
                        <p className='legenda'>Zipline (2017)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/racks"}>
                        <img className="imagen2" alt='Imagen Icono' src="/img/racks/featured.png" />
                        <p className='legenda'>Metal Racks (2017)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/cnc"}>
                        <img className="imagen2" alt='Imagen Icono' src="/img/pd/cnc/featured.png" />
                        <p className='legenda'>Cnc <span>Personal Workshop</span></p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <Link className="boton" to={"pd/corporeos"}>
                        <img className="imagen2" alt='Imagen Icono' src="/img/pd/corporeos/featured.jpg" />
                        <p className='legenda'>Corporeal <span>Personal Workshop</span></p>
                    </Link>
                </Divgroup>
                {/* <Divgroup>
                    <Link className="boton" to={"pd/espacio"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/espacio/featured.png" />
                        <p className='legenda'>Espacio (2016)</p>
                    </Link>
                </Divgroup> */}
                <Divgroup>
                    <Link className="boton" to={"pd/ecovo"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/ecovo/featured.png" />
                        <p className='legenda'>Ecovo (2016)</p>
                    </Link>
                </Divgroup>
                {/* <Divgroup>
                    <Link className="boton" to={"pd/gna"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/gna/featured.png" />
                        <p className='legenda'>GNA (2015)</p>
                    </Link>
                </Divgroup> */}
                {/* <Divgroup>
                    <Link className="boton" to={"pd/renders"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/renders/featured.jpg" />
                        <p className='legenda'>Renders</p>
                    </Link>
                </Divgroup> */}
            </Ul>
            </main>
        </div>
     );
}
 
export default Pd;