import React from 'react';

const Footer = () => {
    return ( 
       
            <footer id="contact" className="contenedor2">
                <div className="data">
                    <div className="datum">
                        <h3><i className="fas fa-envelope-square icono"></i><a
                                href="mailto:juanpablogualchi@gmail.com" rel='noopener noreferrer' target="_blank">juanpablogualchi@gmail.com</a></h3>
                    </div>
                    <div className="datum">
                        <h3><i className="fab fa-linkedin icono"></i><a
                                href="https://www.linkedin.com/in/juanpablogualchi" rel='noopener noreferrer' target="_blank">juanpablogualchi</a></h3>
                    </div>
                    {/* <div className="datum">
                        <h3><i className="fab fa-instagram-square icono"></i><a
                                href="https://www.instagram.com/juangualchi/" rel='noopener noreferrer' target="_blank">juangualchi</a></h3>
                    </div> */}
                    {/* <div className="datum">
                        <h3><i className="fab fa-instagram-square icono"></i><a
                                href="https://www.instagram.com/gualchicnc/" rel='noopener noreferrer' target="_blank">Gualchi CNC</a></h3>
                    </div> */}
                </div>
            </footer>
        

    );
}

export default Footer;


