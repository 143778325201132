import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';


const Ul2 = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em;
    padding: 0;

    
    @media (max-width: 60em) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        padding: 1rem;
    }
`;



const Divgroup = styled.div`
    text-align: center;
`;

const Inicio = () => {
    
    return ( 
        <div>
            <main className="contenedor2">
                <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                </div>
                <Ul2>
                    <Divgroup className="proyectos">
                                <Link className="boton" to="pd">
                                    <img className="imagen2" alt='Imagen Icono' src="/img/pd/industrial.png" />
                                    <p className='legenda'>Industrial Projects</p>
                                </Link>
                            </Divgroup>
                            <Divgroup className="proyectos">
                                <Link className="boton" to="dataanalytics">
                                    <img className="imagen2" alt='Imagen Icono' src="/img/pd/data/featured.png" />
                                    <p className='legenda'>Data Analytics Projects</p>
                                </Link>
                            </Divgroup>
                            <Divgroup className="proyectos">
                                <Link className="boton" to="uxui">
                                    <img className="imagen2" alt='Imagen Icono' src="/img/pd/uxui.png" />
                                    <p className='legenda'>UX UI Projects</p>
                                </Link>  
                            </Divgroup>
                 
                            <Divgroup className="proyectos">
                                <Link className="boton" to="skills">
                                    <img className="imagen2" alt='Imagen Icono' src="/img/sw/featured.png" />
                                    <p className='legenda'>Skills</p>
                                </Link>
                            </Divgroup>
                            <Divgroup className="proyectos">
                                <Link className="boton" to="certificados">
                                    <img className="imagen2" alt='Imagen Icono' src="/img/certificados/featured.png" />
                                    <p className='legenda'>Certificates</p>
                                </Link>
                            </Divgroup>
                </Ul2>
                </main>
        </div>
    );
}
 
export default Inicio;