import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em;
    text-align: center;
    
    @media (max-width: 60em) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        padding: 1rem;
    }
`;

const Divgroup = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`;

const Skills = () => {
    return ( 
        <div>  
            <main className="contenedor2">
                <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <h3 className='scrumb'>Skills</h3>
                </div>
                <div className="resume-section">

                    <h2 className="titulo margin-top2em">LANGUAGES</h2>
                    <Ul className="iconize">
                        <Divgroup className="icon-section">
                            <img className="flag" src="/img/sw/spain.svg" alt="Spanish" title="Spanish"
                                preserveAspectRatio="none" />
                            <span>Native</span>
                        </Divgroup>

                        <Divgroup className="icon-section">
                            <img className="flag" src="/img/sw/uk.svg" alt="English" title="English" preserveAspectRatio="none" />
                            <span>Advanced</span>
                        </Divgroup>

                        <Divgroup className="icon-section">
                            <img className="flag" src="/img/sw/germany.svg" alt="German" title="German" preserveAspectRatio="none" />
                            <span>Basic</span>
                        </Divgroup>
                    </Ul>

                    <h2 className="titulo">SOFTWARE</h2>
                    <Ul className="iconize">
                        <Divgroup className="icon-section">
                            <img src="/img/sw/solidworks.png" alt="SolidWorks" title="SolidWorks" />
                            <span>SolidWorks</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/inventor.png" alt="Inventor" title="Inventor" />
                            <span>Inventor</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/rhinoceros.png" alt="Rhinoceros" title="Rhinoceros" />
                            <span>Rhinoceros</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/autocad.ico" alt="Autocad" title="Autocad" />
                            <span>Autocad</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/keyshot.png" alt="Keyshot" title="Keyshot" />
                            <span>Keyshot</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/ai.svg" alt="Illustrator" title="Illustrator" />
                            <span>Illustrator</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/xd.svg" alt="XD" title="XD" />
                            <span>XD</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/ps.svg" alt="Photoshop" title="Photoshop" />
                            <span>Photoshop</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/coreldraw.png" alt="CorelDraw" title="CorelDraw" />
                            <span>CorelDraw</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/simplify3d.ico" alt="Simplify 3D" title="Simplify 3D" />
                            <span>Simplify 3D</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/aspire.ico" alt="Aspire" title="Vectric Aspire" />
                            <span>Aspire</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/html.svg" alt="html5" title="hmtl5" />
                            <span>HTML5</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/css.svg" alt="css" title="css" />
                            <span>CSS</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/sass.png" alt="sass" title="sass" />
                            <span>SASS</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/js.svg" alt="js" title="js" />
                            <span>JavaScript</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/visualstudiocode.png" alt="vsc" title="vsc" />
                            <span>VS Code</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/mach3.png" alt="m3" title="m3" />
                            <span>Mach3</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/Git.svg" alt="git" title="Git" />
                            <span>Git</span>
                        </Divgroup>
                        {/* <Divgroup className="icon-section">
                            <img src="/img/sw/GitHub.png" alt="github" title="Github" />
                            <span>GitHub</span>
                        </Divgroup>  */}
                        <Divgroup className="icon-section">
                            <img src="/img/sw/node.png" alt="node" title="node" />
                            <span>Node</span>
                        </Divgroup> 
                        <Divgroup className="icon-section">
                            <img src="/img/sw/react.png" alt="react" title="react" />
                            <span>React</span>
                        </Divgroup> 
                        <Divgroup className="icon-section">
                            <img src="/img/sw/npm.png" alt="npm" title="npm" />
                            <span>Npm</span>
                        </Divgroup> 
                        {/* <Divgroup className="icon-section">
                            <img src="/img/sw/redux.png" alt="redux" title="redux" />
                            <span>Redux</span>
                        </Divgroup>  */}
                        <Divgroup className="icon-section">
                            <img src="/img/sw/tableau.svg" alt="tableau" title="tableau" />
                            <span>Tableau</span>
                        </Divgroup> 
                        <Divgroup className="icon-section">
                            <img src="/img/sw/powerbi.png" alt="powerbi" title="powerbi" />
                            <span>Power BI</span>
                        </Divgroup> 
                        <Divgroup className="icon-section">
                            <img src="/img/sw/sql.png" alt="sql" title="sql" />
                            <span>SQL</span>
                        </Divgroup> 
                        <Divgroup className="icon-section">
                            <img src="/img/sw/mysql.svg" alt="mysql" title="mysql" />
                            <span>mySQL</span>
                        </Divgroup> 
                        <Divgroup className="icon-section">
                            <img src="/img/sw/sqlserver.svg" alt="sqlserver" title="sqlserver" />
                            <span>SQLserver</span>
                        </Divgroup> 
                        <Divgroup className="icon-section">
                            <img src="/img/sw/python.png" alt="python" title="python" />
                            <span>Python</span>
                        </Divgroup> 
                        <Divgroup className="icon-section">
                            <img src="/img/sw/azure.svg" alt="azure" title="azure" />
                            <span>Azure</span>
                        </Divgroup> 
                        <Divgroup className="icon-section">
                            <img src="/img/sw/SAP.svg" alt="sap" title="sap" />
                            <span>SAP BO</span>
                        </Divgroup> 
                    </Ul>

                    <h2 className="titulo">OTHER KNOWLEDGE</h2>
                    <Ul className="iconize">
                        <Divgroup className="icon-section">
                            <img src="/img/sw/3d.svg" alt="SolidWorks" title="SolidWorks" />
                            <span>3d printer operator</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/milling.svg" alt="SolidWorks" title="SolidWorks" />
                            <span>CNC Router operator</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/laser.svg" alt="SolidWorks" title="SolidWorks" />
                            <span>CNC Laser, Plasma, Oxy, Waterjet cutter files design</span>
                        </Divgroup>
                        <Divgroup className="icon-section">
                            <img src="/img/sw/fold.svg" alt="SolidWorks" title="SolidWorks" />
                            <span>Folded parts design</span>
                        </Divgroup>
                    </Ul>
                        
                    
                </div>
            </main>
        </div>
     );
}
 
export default Skills;