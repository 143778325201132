import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        padding: 1rem;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const UxUi = () => {
    return ( 
        <div>
            <main className="contenedor2">
            <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <h3 className='scrumb'>Projects</h3>
                </div>
            <Ul>
                <Divgroup>
                    <Link className="boton" to={"uxui/ui"}>
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/ui/featured.jpg" />
                        <p className='legenda'>UI Prototype (2020)</p>
                    </Link>
                </Divgroup>
                <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href="https://trimsa.com.ar/">
                        <img className="imagen2" alt='Imagen icono' src="/img/pd/trimsa/featured.jpg" />
                        <p className='legenda'>Trimsa Web (2018)</p>
                    </a>
                </Divgroup>
            </Ul>
            </main>
        </div>
     );
}
 
export default UxUi;