import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Ul2 = styled.ul`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 0.5em;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
    }
`;

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5em;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const Tirolesa = () => {
    return ( 
        <div>
            <main className="contenedor2">
                <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <Link to={"../pd"}><h3 className='scrumb'>Projects</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <h3 className='scrumb'>Zipline</h3>
                </div>
                <h3 className="copete">Project carried out for amusement park</h3>
                <Ul2>
                    <Divgroup>
                        <video className="video" src="/video/tirolesa.mp4" autoplay="true" muted='muted' controls loop="true"/>
                    </Divgroup>
                <Ul>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen tirolesa' src="/img/pd/tirolesa/01.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen tirolesa' src="/img/pd/tirolesa/02.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen tirolesa' src="/img/pd/tirolesa/04.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen tirolesa' src="/img/pd/tirolesa/03.jpg" />
                    </Divgroup>
                    {/*<Divgroup>
                        <img className="imagen1" alt='Imagen tirolesa' src="/img/pd/tirolesa/05.jpg" />
                    </Divgroup>*/}
                    <Divgroup>
                        <img className="imagen1" alt='Imagen tirolesa' src="/img/pd/tirolesa/06.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen tirolesa' src="/img/pd/tirolesa/07.jpg" />
                    </Divgroup>
                </Ul>
                </Ul2>
            </main>
        </div>
     );
}
 
export default Tirolesa;