import React from 'react';
import {Link} from 'react-router-dom';


const Header = () => {
    return ( 
        <header className="contenedor2">
            <div className="headergrid">
                <div className="section-background cola">
                    <Link to={"../"}><h2 className="carreras">JUAN P. GUALCHI</h2></Link> 
                </div>
                <div className="section-background colb">
                    <h2 className="carreras">INDUSTRIAL<span>DESIGNER</span></h2>
                    {/*<h2 className="carreras">PRODUCTION<span>PLANNER</span></h2>*/}
                    <h2 className="carreras">DATA<span>ANALYST & ENGINEER</span></h2>
                    {/* <h2 className="carreras">DESARROLLADOR WEB</h2> */}
                </div>
               <img className="perfil colc" alt="imagen perfil" src="/img/perfil.jpg"/>
            </div>
        </header>
    );
}
 
export default Header;