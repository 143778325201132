import React from 'react';
import styled from '@emotion/styled';

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em 0;
    padding: 0 1em;
    
    

    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
        padding: 0;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const Id = () => {
    return ( 
        <div>
            {/* <div className="section-background nav-background">
                <nav>
                    <Link to={"../"} className="motion">INICIO</Link>
                    <a href="#contact" className="motion">CONTACTO</a>
                </nav>
            </div> */}
            <main className="contenedor">
                <h2 className="titulo">DESARROLLO INDUSTRIAL</h2>
                <p className='encabezado'>Desarrollo de estructuras para manejo de materiales de principio a fin. Etapas del proceso: relevamiento de dimensiones y necesidades de la estructura, diseño de la misma, pedido de materiales, creación de planos para sector de fabricación y finalmente verificado de producto</p>
                <Ul>
                    
                    <Divgroup>
                        <img className="imagen1" alt='Imagen id' src="/img/Id/05.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen id' src="/img/Id/06.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen id' src="/img/Id/07.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen id' src="/img/Id/08.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen id' src="/img/Id/09.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen id' src="/img/Id/10.jpg" />
                    </Divgroup>

                </Ul>
            </main>
            
        </div>
     );
}
 
export default Id;