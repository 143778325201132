import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const Renders = () => {
    return ( 
        <div>
            <main className="contenedor2">
                <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <Link to={"../pd"}><h3 className='scrumb'>Projects</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <h3 className='scrumb'>Renders</h3>
                </div>
                <Ul>
    
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/02.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/04.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/01.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/05.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/06.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/03.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/07.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/08.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/09.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/11.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/12.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen render' src="/img/pd/renders/13.jpg" />
                    </Divgroup>
                </Ul>
            </main>
            
        </div>
    );
}
 
export default Renders;