import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const Cnc = () => {
    return ( 
        <div>
            <main className="contenedor2">
                <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <Link to={"../pd"}><h3 className='scrumb'>Projects</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <h3 className='scrumb'>Cnc</h3>
                </div>
                <Ul>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/07.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/03.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/04.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/05.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/06.jpg" />
                    </Divgroup>  
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/02.jpg" />
                    </Divgroup>             
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/08.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/09.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/10.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/23.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/12.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/13.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/14.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/15.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/11.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/18.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/19.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/20.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/21.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/26.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/25.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/27.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/29.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/32.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/30.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/31.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/33.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/34.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/36.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/37.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/38.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/39.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/40.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/41.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/42.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/43.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/44.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/45.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/46.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/47.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/48.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/17.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/22.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/24.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen cnc' src="/img/pd/cnc/28.jpg" />
                    </Divgroup>
                </Ul> 

            </main>
        </div>
     );
    }
 
export default Cnc;