import React from 'react';
import styled from '@emotion/styled';



const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em;
    padding: 0 1em;

    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
        padding: 0;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const Apps = () => {

    return (
        <div>
            {/* <div className="section-background nav-background">
                <nav>
                    <Link to={"../"} className="motion">INICIO</Link>
                    <a href="#contact" className="motion">CONTACTO</a>
                </nav>
            </div> */}

            <main className="contenedor">
                <h2 className="titulo">APPS</h2>
                <p className='encabezado'>Proyectos de nivel inicial en frontend development</p>
                <Ul>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://b-ienes-raices.netlify.app/'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5ed5aa75b694bbc74c27e2e7/screenshot.png" />
                        <p className='legenda'>Bienes Raíces</p>
                    </a>
                    <p className='app-descripcion'>App creada con React, Gatsby, Graphql & Strapi</p>
                    </Divgroup>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://n-ext-productos.netlify.app/'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5ecedb94d3a0f6fa3b0e0cf5/screenshot.png" />
                        <p className='legenda'>App de Productos</p>
                    </a>
                    <p className='app-descripcion'>App creada con React, Next & Firebase</p>
                    </Divgroup>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://b-uscador-de-bebidas.netlify.app'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5eb857f28a29b2694aa4909d/screenshot.png" />
                        <p className='legenda'>Recetas de Bebidas</p>
                    </a>
                    <p className='app-descripcion'>App comunicada con rest Api</p>
                    </Divgroup>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://l-etras-canciones.netlify.app'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5eb5f40855780e194a01a0e1/screenshot.png" />
                        <p className='legenda'>Letras de Canciones</p>
                    </a>
                    <p className='app-descripcion'>App comunicada con rest Api</p>
                    </Divgroup>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://p-ixalay-imagenes.netlify.app'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5eb5a9b602f3a75b9b17c2eb/screenshot.png" />
                        <p className='legenda'>Buscador de Imágenes</p>
                    </a>
                    <p className='app-descripcion'>App comunicada con rest Api</p>
                    </Divgroup>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://c-riptomonedas-cotizador.netlify.app'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5eb201ce1492d0128c3f330e/screenshot.png" />
                        <p className='legenda'>Cotizador de Cryptomonedas</p>
                    </a>
                    <p className='app-descripcion'>App comunicada con rest Api</p>
                    </Divgroup>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://b-reaking-bad-frases.netlify.app/'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5eb0d84c8d929a2d5841fa80/screenshot.png" />
                        <p className='legenda'>Frases Breaking Bad</p>
                    </a>
                    <p className='app-descripcion'>App comunicada con rest Api</p>
                    </Divgroup>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://p-resupuesto.netlify.app/'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5eb0d4fd268b39370b9045bb/screenshot.png" />
                        <p className='legenda'>Presupuesto y Gastos</p>
                    </a>
                    <p className='app-descripcion'>Ejercicio con Javascript</p>
                    </Divgroup>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://c-otizador-de-seguros.netlify.app/'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5eb0d5178d929a3e2d41f6fa/screenshot.png" />
                        <p className='legenda'>Cotizador de Seguros</p>
                    </a>
                    <p className='app-descripcion'>Ejercicio con Javascript</p>
                    </Divgroup>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://c-lima-react.netlify.app/'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5eb0d53f1ab41795d59508bd/screenshot.png" />
                        <p className='legenda'>Clima</p>
                    </a>
                    <p className='app-descripcion'>App comunicada con rest Api</p>
                    </Divgroup>
                    <Divgroup>
                    <a className="boton" target='_blank' rel="noopener noreferrer" href='https://a-dministrador-de-pacientes.netlify.app/'>
                        <img className="imagen3" alt='Imagen icono' src="https://353a23c500dde3b2ad58-c49fe7e7355d384845270f4a7a0a7aa1.ssl.cf2.rackcdn.com/5eab654a102789b60885f666/screenshot.png" />
                        <p className='legenda'>Administrador de Pacientes</p>
                    </a>
                    <p className='app-descripcion'>Ejercicio con Javascript</p>
                    </Divgroup>
                </Ul>
            </main>
        </div>
     );
}
 
export default Apps;