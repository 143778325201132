import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Ul2 = styled.ul`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 0.5em;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
    }
`;

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const Elevator = () => {
    return ( 
        <div>
            <main className="contenedor2">
                <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <Link to={"../pd"}><h3 className='scrumb'>Projects</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <h3 className='scrumb'>Elevator</h3>
                </div>
                <Ul2>
                    <Divgroup>
                        <video className="video" src="/video/elevator.mp4" muted='muted' autoplay="true" controls loop="true"/>
                    </Divgroup>
                <Ul>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen elevador' src="/img/pd/montacargas/1.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen elevador' src="/img/pd/montacargas/2.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen elevador' src="/img/pd/montacargas/3.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <img className="imagen1" alt='Imagen elevador' src="/img/pd/montacargas/4.jpg" />
                    </Divgroup>
                </Ul>
                </Ul2>
            </main>
        </div>
     );
}
 
export default Elevator;