import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5em;
    padding: 0;
    margin: 2em 0;
    
    @media (max-width: 60em) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
    }
`;

const Divgroup = styled.div`
    text-align: center;
`;

const Certificados = () => {
    return ( 
        <div> 
            <main className="contenedor2">
                <div className="guide">
                    <Link to={"../"}><h3 className='scrumb'>Home</h3></Link>
                    <h3 className='scrumb'>-</h3>
                    <h3 className='scrumb'>Certificates</h3>
                </div>
                <Ul>
                    <Divgroup>
                        <p className='encabezado'>Industrial Designer - Buenos Aires University</p>              
                        <img className="imagen1" alt='Imagen certificados' src="/img/certificados/di.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <p className='encabezado'>Data Analytics - Digital House</p>
                        <img className="imagen1" alt='Imagen certificados' src="/img/certificados/dataanalytics.jpg" />                
                    </Divgroup>
                    <Divgroup>
                        <p className='encabezado'>Python (Data Analytics) - Udemy</p>
                        <img className="imagen1" alt='Imagen certificados' src="/img/certificados/python.jpg" />                
                    </Divgroup>
                    <Divgroup>
                        <p className='encabezado'>Production Control and Planning - National Tecnology University</p>
                        <img className="imagen1" alt='Imagen certificados' src="/img/certificados/planificacion.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <p className='encabezado'>UX - IxD Designer - National Tecnology University</p>
                        <img className="imagen1" alt='Imagen certificados' src="/img/certificados/uxixd.jpg" />
                    </Divgroup>
                    <Divgroup>
                        <p className='encabezado'>Javascript - Udemy</p>
                        <img className="imagen1" alt='Imagen certificados' src="/img/certificados/javascript.jpg" /> 
                    </Divgroup>
                    <Divgroup>
                        <p className='encabezado'>ReactJS - Udemy</p>
                        <img className="imagen1" alt='Imagen certificados' src="/img/certificados/react.jpg" />                
                    </Divgroup>
                    <Divgroup>
                        <p className='encabezado'>CSS - Udemy</p>
                        <img className="imagen1" alt='Imagen certificados' src="/img/certificados/cssgridflex.jpg" />                
                    </Divgroup>
                </Ul>
            </main>
        </div>
     );
}
 
export default Certificados;