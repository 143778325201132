import React from 'react';
import { HashRouter, Route, Switch }from 'react-router-dom';
import Header from './componentes/Header';
import Inicio from './componentes/Inicio';
import Footer from './componentes/Footer';
import Skills from './componentes/Skills';
import Pd from './componentes/Pd';
import UxUi from './componentes/UxUi';
import Cnc from './componentes/Pd/Cnc';
import Corporeos from './componentes/Pd/Corporeos';
import Id from './componentes/Id';
import Racks from './componentes/Pd/Racks';
import Chipi from './componentes/Pd/Chipi';
import Rondo from './componentes/Pd/Rondo';
import Ecovo from './componentes/Pd/Ecovo';
import Gna from './componentes/Pd/Gna';
import Espacio from './componentes/Pd/Espacio';
import Renders from './componentes/Pd/Renders';
import Parking from './componentes/Pd/Parking';
import Ui from './componentes/Pd/Ui';
import Montacargas from './componentes/Pd/Montacargas';
import Tirolesa from './componentes/Pd/Tirolesa';
import Apps from './componentes/Apps';
import Certificados from './componentes/Certificados';
import ScrollToTop from './componentes/ScrollToTop';
import Ballclock from './componentes/Pd/Ballclock';
import DataAnalytics from './componentes/DataAnalytics';
import Metrovias from './componentes/Pd/Metrovias';
import Betball from './componentes/Pd/Betball';
import Cardales from './componentes/Pd/Cardales';
import Solarium from './componentes/Pd/Solarium';
import Totem from './componentes/Pd/Totem';



function App() {

  // const [cargando, guardarCargando] = useState(false);

    return (
      <HashRouter >
        <div className="App">
          <Header/>
          <ScrollToTop />
          <Switch>
            <Route exact path='/' component={Inicio} />
            <Route exact path='/skills' component={Skills} />
            <Route exact path='/certificados' component={Certificados} />
            <Route exact path='/pd' component={Pd} />
            <Route exact path='/uxui' component={UxUi} />
            <Route exact path='/id' component={Id} />
            <Route exact path='/apps' component={Apps} />
            <Route exact path='/dataanalytics' component={DataAnalytics} />
            {/* sub grupo */}
            <Route exact path='/pd/cnc' component={Cnc} />
            <Route exact path='/pd/corporeos' component={Corporeos} />
            <Route exact path='/pd/racks' component={Racks} />
            <Route exact path='/pd/chipi' component={Chipi} />
            <Route exact path='/pd/rondo' component={Rondo} />
            <Route exact path='/pd/ecovo' component={Ecovo} />
            <Route exact path='/pd/espacio' component={Espacio} />
            <Route exact path='/pd/renders' component={Renders} />
            <Route exact path='/pd/parking' component={Parking} />
            <Route exact path='/pd/tirolesa' component={Tirolesa} />
            <Route exact path='/pd/montacargas' component={Montacargas} />
            <Route exact path='/pd/ballclock' component={Ballclock} />
            <Route exact path='/pd/gna' component={Gna} />
            <Route exact path='/uxui/ui' component={Ui} />
            <Route exact path='/dataanalytics/metrovias' component={Metrovias} />
            <Route exact path='/dataanalytics/betball' component={Betball} />
            <Route exact path='/pd/cardales' component={Cardales} />
            <Route exact path='/pd/solarium' component={Solarium} />
            <Route exact path='/pd/totem' component={Totem} />
          </Switch>   
          <Footer/>
        </div>
      </HashRouter>
    );
}



export default App;
